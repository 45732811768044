
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wdth,wght@0,6..12,101.1,200..1000;1,6..12,101.1,200..1000&display=swap');
/* +Subrayada */

body{
  font-family: "Montserrat", serif;
}

.form-select-lg{
  font-size: inherit;
  padding-left: .75rem;
  height: 48px;

}
.form-select-selected{
  color: #121416;
  opacity: .6;
}
.cust-form-group .form-select option:first-child > span{
  color: red;
  background-color: yellow; 
}
.montserrat-subrayada-regular {
  font-weight: 700;
  font-style: normal;
}
.poppins-thin {
  font-weight: 500;
  font-style: normal;
}
.cust-pl-2{
  padding-left: 15px;
}
.cust-pr-2{
  padding-right: 15px;
}
.cut-mr-1{
  margin-right: 5px;
}
.cust-bos-card .banner-fixed h3{
  font-family: sans-serif;
  letter-spacing: .9px;
}
.montserrat-subrayada-regular-com{
  font-family: "Montserrat", serif;
}
.montserrat-subrayada-regular {
  font-family: "Montserrat", serif;
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
}
.cus-gender .col-4{
  padding-left: 10px;
  padding-right: 10px;
}
.cus-gender .hov-gr{
  padding-left: 0;
  padding-right: 0;
}
.form-control{
  border: solid 1px #121416 rgba(0,0,0,.2);
}

.b-option p{
  color: #121416;
  font-size: 16px;

}
.blur-blue{
  color:#5B6F8E;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.form-control{
  height: 48px;
  padding-top: 0;
  padding-bottom: 0;
}
.cust-form-group{
  margin-bottom: 20px;
}
button{
  height: 48px;
  border-radius: 8px;
}
.cut-row-form{
  display: flex;
  justify-content:space-between;
}

.cut-row-form .col-4{
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 31.33333333%;
}
.cut-row-form .col{
  padding-left: 0 !important;
   padding-right: 0 !important;
   flex: inherit;
   width: inherit;
}
.cut-row-form button{
  width: 101px;
}
.cut-row-form-emj{
  justify-content: space-between;
}
.cut-row-form-emj button{
  width: 100%;
}
.cut-row-form-emj .col{
  width: 90%;
}
.cut-row-form-emj .col:nth-child(1){
  padding-right: 10px !important;
}
.cut-row-form-emj .col:nth-child(2){
  padding-left: 10px !important;
}
.cut-row-form-role{
  display: flex;
}
.cut-row-form-role .col button{
  padding-left: 10px;
  padding-right: 10px;
}
.form-control::placeholder{
  font-size: 16px;
  color: #121416;
  opacity: .6;
}
input{
  font-weight: bold;
  color: 121416;
}

.custom-btn {
  background: #fff;
  border-radius: 30px;
  background: rgb(178, 9, 93);
  outline: none;
  border: none;
  letter-spacing: .4px;
  text-shadow: 0 0 1px white;
}
.situation-option .hov-none:active{
 background:#13908C ;
}
.custom-btn:active{
  background: rgb(178, 9, 93) !important;
}
.custom-btn:hover{
  background: rgb(178, 9, 93) !important;
}
.text-justify{
  text-align: justify;
}
.hov-gr{
  border: solid 1px #dee2e6;
}
.hov-gr:hover{
  background: #13908C;
  border: solid 1px #13908C;
}
.selected-clr{
  background: #13908C !important;
  color: white;
}

input:focus{
  color:rgba(19,144,140,255);
  outline: rgba(19,144,140,255);
}
.stepper-fix{
  position: fixed;
  z-index: 100;
  left: 50%;
  top: -11px;
  transform: translate(-50%, 10px);
  background: #F6F6F6;
  width: 100%;
}
.continue-btn{
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 0;
}
.continue-btn:active{
 background: rgb(178, 9, 93) !important;
}
.continue-btn:hover{
  background: rgb(178, 9, 93);
}
.b-option{
  color:#121416;
  background: #F6F6F6;
  border-radius: 8px;
}

@media(min-width:530px){
  .stepper-fix{
    max-width: 500px; 
    width: 500px;
  }
  .continue-btn{
    max-width: 500px; 
    width: 500px;
  }

}

.custon-progress-bar{
  max-width: 540px;
}
.back-arrow{
  position: fixed;
  font-size: 24px;
  top: 48px;
  left: 50%;
  transform: translate(-50%, 0px);
  z-index: 100;
  background: #bdbdbd;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.cut-pri-dia{
  width:97vw
}


@media(min-width:900px){
  .cut-pri-dia{
    width:80vw
  }
  .cust-bos-card{
    max-width: 400px;
  }
  
}
@media(min-width:1100px){
  .cut-pri-dia{
    width:50vw
  }
}

.err-p{
  opacity: .7;
}
.back-arrow-stepper{
  font-size: 20px;
  top: 48px;
  left: 0%;
  z-index: 100;
  background: rgb(232 232 232);
  color: white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hov-none{
  padding-left: 0;
  padding-right: 0;
}
.hov-none:hover{
  background: none;
  color: inherit;
}
.Disabled{
  background: gray;
  pointer-events: none;
}
.Disabled:hover{
  background: gray;
}
.banner-fixed > {
 
}
.yellow{
  color: #606060;
}
.text-muted{
  color: #121416;
  opacity: .6;
  font-size: 14px;
  padding-top: 10px;
}
.bor-container{
  border: solid 1px rgb(214, 214, 214);
}
.bor-none{
  padding-left: 0;
  padding-right: 0;
}
.custom-loader{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.opa-btn{
 background: rgb(228 165 197);
}
.opa-btn:hover{
  background: rgb(228 165 197) !important;
}



/* custom  */ 
.custom-dropdown {
  position: relative;
  width: 100%;
  max-width: 300px;
  font-family: "Arial", sans-serif;
}

.dropdown-header {
  padding: 10px 15px;
  background: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  color: #333;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-header.open {
  border-color: #007aff;
}

.dropdown-options {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.dropdown-option {
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  color: #333;
}

.dropdown-option:hover,
.dropdown-option.selected {
  background: #f0f0f0;
  color: #007aff; /* iOS blue color */
}

.dropdown-options::-webkit-scrollbar {
  width: 6px;
}

.dropdown-options::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 4px;
}

.dropdown-options::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}
